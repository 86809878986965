<template>
	<v-app>
		<v-main>

			<router-view />

			<v-dialog v-model="$store.state.isLoaderShow" max-width="70">
				<v-progress-circular
					:size="70"
					indeterminate
					class="primary--text"
				/>
			</v-dialog>
			
		</v-main>
	</v-app>
</template>

<script>
	export default {
		name: "App",

		components: {},

		data: () => ({
			//
		}),
	};
</script>
