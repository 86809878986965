// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify, VDataTable, VDatePicker, VApp, VTextField } from 'vuetify'

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#3f51b5',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
                bg_theme: '#3f51b5'
            },
        },
    },
}

export default createVuetify({
    components: {
        VDataTable,
        VDatePicker,  // Include VDatePicker if you're using v-date-picker in your templates
        VApp,        // VApp is generally needed as a wrapper in your App.vue
        VTextField   // Commonly used form input
    },
    theme: {
      defaultTheme: 'myCustomTheme',
      themes: {
        myCustomTheme: {
          dark: false,
          colors: {
            primary: '#3f51b5',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
            bg_theme: '#3f51b5'
          }
        }
      }
    }
})
