import { createStore } from 'vuex'

export default createStore({
  state: {
    menuList: [],
		userDetail: {},
		isLoaderShow: false,
		isMobileDevice: false,
    postDirectSessionLastTheraphist : null,
    postConsultSessionLastTherapist : null,
    postMakeUpSessionLastTherapist : null,
    

  },
  getters: {
  },
  mutations: {
    setMenu(state, data) {
			state.menuList = data;
		},
		setUserDetail(state, data) {
			state.userDetail = data;
		}
  },
  actions: {
  },
  modules: {
  }
})
